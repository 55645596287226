.font-sm {
  font-size: 0.75rem;
}
.font-lg {
  font-size: 2.25rem;
}
body {
  font-family: "Google Sans", monospace;
  margin: 0;
  color: rgb(32, 33, 36);
  font-weight: 300;
  line-height: 1.7rem;
}

#projects,
#about {
  padding-top: 40px;
}

.email {
  border-top: 3px solid;
  padding: 10px 0;
  width: fit-content;
}

.external {
  display: flex;
  gap: 10px;
}

.external img {
  width: 20px;
  height: 20px;
  filter: grayscale(100%);
  mix-blend-mode: multiply;
}
.external img:hover {
  opacity: 0.5;
}

.about-holder {
  background: rgb(255, 193, 7);
  width: 100%;
  justify-content: center;
  display: flex;
  padding-bottom: 60px;
}

h1 {
  margin-top: 0;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 2.75rem;
}

index-component {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topbar {
  display: flex;
  justify-content: center;
  position: sticky;
  gap: 30px;
  top: 0;
  background: white;
  width: 100%;
  padding: 5px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  z-index: 10;
  box-sizing: border-box;
}

.nav {
  cursor: pointer;
}

.nav a {
  text-decoration: inherit; /* no underline */
}

.content {
  padding: 5px;
  max-width: 600px;
}

.about-holder a {
  text-decoration: underline;
  color: inherit;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  font-weight: 400;
  color: #999;
}

.upper{
  text-transform: uppercase;
}

.project {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 40px;
  padding-top: 10px;
}
.img-holder {
  flex: 0 0 400px;
  height: 150px;
  overflow: hidden;
  background: #fff;
  border-radius: 15px;
  filter: grayscale(50%) brightness(90%);
}
.project img {
  width: 100%;
  transition: transform 0.25s ease;
}

.project img:hover {
  transform: scale(1.2);
}

a {
  color: #ffa000;
  text-decoration: none;
  line-height: 1.5;
}

a:hover {
  color: #ff5722;
}

@media screen and (max-width: 1200px) {
  .font-sm, .topbar {
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 300;
  }
  .content {
    max-width: unset;
    line-height: 3.7rem;
    padding: 45px;
    font-size: 2rem;
  }
  .project {
    flex-direction: column;
    padding-bottom: 140px;
  }
  .img-holder {
    height: unset;
    flex: unset;
  }
  .external img {
    width: 50px;
    height: 50px;
  }
}
